// import pro1 from "../assets/project1.png";
// import pro2 from "../assets/project2.png";
// import pro3 from "../assets/project3.png";
import react1 from "../assets/mern.jpg";
// import react2 from "../assets/mern2.jpg"
// import react3 from "../assets/mern3.webp"
import native from "../assets/native.jpeg";

const ProjectCardData = [
  {
    imgsrc: react1,
    title: "The Ultimate Online Coding Judge",
    text: "Developed a Scalable Online Coding Judge supporting compilers from 4+ mainstream languages. Integrated Google’s latest AI based LLM - Gemini for a robust code analysis. Implemented (CI/CD) pipelines that includes GitHub, DockerHub, AWS ECR and AWS EC2. Containerized with Docker and deployed on the AWS Cloud Platform.",
    view: "https://the-ultimate-coding-compiler.netlify.app/problems/0",
    source: "https://github.com/ipranavprashant/Online-Judge",
  },
  {
    imgsrc: react1,
    title: "Meesho Clone",
    text: "A replica of the Meesho e-commerce platform, this clone features a clean, intuitive UI and essential functionalities, such as product browsing, search, and a simulated cart. Developed with a focus on frontend development using React to offer a seamless user experience.",
    view: "https://meeshoclonebypranav.netlify.app/",
    source: "https://github.com/ipranavprashant/Meesho-Clone",
  },
  {
    imgsrc: react1,
    title: "Vertex Classes : Ed-Tech",
    text: "Developing a full-stack EdTech platform with login functionalities and session management for student enrollment and course management, providing seamless registration processes, course selection, and comprehensive management tools.",
    view: "https://vertexclass.co.in",
    source: "https://github.com/ipranavprashant/Vertex-Classes",
  },
  {
    imgsrc: react1,
    title: "Ping Me the Link!",
    text: "Developed a virtual data server as a hobby project named 'Ping Me the Link.' This file-sharing application is optimized for closed environments, generating links for uploaded files that others can download, all without requiring an internet connection!",
    view: "https://github.com/ipranavprashant/File-Sharing-App",
    source: "https://github.com/ipranavprashant/File-Sharing-App",
  },
  {
    imgsrc: native,
    title: "Plate2Purpose : Android/IOS",
    text: "A cross platform mobile app, that is dedicated to fighting food waste and hunger. It helps hotel realise the excess foods they make. It creates a sustainable ecosystem that not only reduces food waste but also addresses the critical issue of hunger in communities.",
    view: "https://github.com/ipranavprashant/Plate2Purpose",
    source: "https://github.com/ipranavprashant/Plate2Purpose",
  },
  {
    imgsrc: react1,
    title: "FOSSCell NIT Calicut",
    text: "Developed in a team the oﬃcial Website aimed at assisting individuals interested in staying informedabout the world of open source technology.",
    view: "https://foss-cell.netlify.app/",
    source: "https://github.com/FOSSCellNITCalicut/FossCellNITC",
  },
  {
    imgsrc: react1,
    title: "Enquire Quiz Club's Website",
    text: "The project involves developing a visually striking website dedicated to showcasing the club's motto, events, members, and the contact details. With a primary focus on revitalizing the club's event registration systems, this product boasts a proven track record of seamlessly handling thousands of registrations. It offers a user-friendly and efficient platform to engage members and streamline the registration process for various events.",
    view: "https://successenquire.netlify.app/",
    source: "https://github.com/ipranavprashant/Enquire2k23",
  },
  {
    imgsrc: react1,
    title: "Lost and Found Management System",
    text: "The Lost and Found System in the DBMS is designed to assist individuals in reporting lost items, searching for lost items, and claiming found items. It provides a structured approach to manage lost and found belongings efficiently within an organization or community.",
    view: "https://successdbms.netlify.app/",
    source:
      "https://github.com/ipranavprashant/Lost-and-Found-Management-System",
  },
  {
    imgsrc: react1,
    title: "Google WoW : Kerala Chapter",
    text: "I had the privilege of being a part of the esteemed core development team ofGDSC Kerala. As a key member, I Lead a team in crafting and shaping GDSCKerala's remarkable website. This project was a collaborative effort among four web developers from different colleges in Kerala.",
    view: "https://wowkerala.netlify.app/",
    source: "https://github.com/ipranavprashant/googlewow",
  },
  {
    imgsrc: react1,
    title: "Nireekshan AI - Platform Under Central Govt.",
    text: "In Progress!",
    view: "https://nireekshanai.netlify.app/",
    source: "https://github.com/ipranavprashant/NireekshanAI",
  },
  {
    imgsrc: react1,
    title: "Weather App",
    text: "A react based weather app, made using the freeweatherapi with a beautiful modern UI.",
    view: "https://pranavreactweatherapp.netlify.app/",
    source: "https://github.com/ipranavprashant/WeatherApp-using-React.Js",
  },
  {
    imgsrc: react1,
    title: "Web Solutions",
    text: "A modern website that motives to provide the best web solutions to its client.",
    view: "https://websolutionsbypranav.netlify.app/",
    source: "https://github.com/ipranavprashant/Web-Solutions",
  },
  {
    imgsrc: react1,
    title: "Consultancy Firm",
    text: "Created a professional consultancy ﬁrm website showcasing services, expertise, and contact information.",
    view: "https://consultancy-rrxk.onrender.com/",
    source: "https://github.com/ipranavprashant/Consultancy-Firm",
  },
  {
    imgsrc: react1,
    title: "My Personal Portfolio",
    text: "Yeah! Exactly the one you are at right now :)",
    view: "https://pranavprashant.in/",
    source: "https://github.com/ipranavprashant/Portfolio",
  },
];

export default ProjectCardData;
